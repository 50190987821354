import { get, includes, isEqual, startCase } from "lodash";
import React from "react";
import Button from "../Button";
import Card from "../Card";
import CalendarIcon from "../icons/Calendar.svg";
import CheckmarkSmallIcon from "../icons/CheckmarkSmall.svg";
import CreditCardIcon from "../icons/CreditCard.svg";
import TrashIcon from "../icons/Trash.svg";
import * as styles from "./index.module.scss";

export default ({
  T,
  appStyles,
  paymentMethod,
  removePaymentMethod,
  defaultPaymentMethodToken,
  backPath,
  setDefaultPaymentMethod,
}) => (
  <Card appStyles={appStyles}>
    <Card.Title
      appStyles={appStyles}
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <span style={{ textTransform: "capitalize" }}>
        {T(humanReadablePaymentTypeString(paymentMethod.typeString))}
      </span>
      <Button
        appStyles={appStyles}
        linkStyle
        onClick={removePaymentMethod(paymentMethod.token)}
        style={{ flexGrow: 0, display: "flex", alignItems: "center" }}
      >
        <TrashIcon
          style={{
            color: appStyles.actionColor,
            width: 17,
            height: 17,
            ...(appStyles.rtl ? { marginLeft: 6 } : { marginRight: 6 }),
          }}
          className={styles.TrashIcon}
        />
        <span style={{ fontSize: "1rem" }}>{T("Remove")}</span>
      </Button>
    </Card.Title>
    <Card.Content style={{ display: "flex", flexDirection: "column" }}>
      <span>
        <CreditCardIcon
          style={{
            height: 15,
            width: 15,
            ...(appStyles.rtl ? { marginLeft: 6 } : { marginRight: 6 }),
          }}
        />
        <span>
          <span>{T("Card Number (Last 4 digits):")}</span>
          <span style={{ fontWeight: "bold" }}>
            {" "}
            {get(paymentMethod, "displayString")}
          </span>
        </span>
      </span>
      <span style={{ margin: "10px 0" }}>
        <CalendarIcon
          style={{
            color: appStyles.textColor,
            height: 15,
            width: 15,
            ...(appStyles.rtl ? { marginLeft: 6 } : { marginRight: 6 }),
          }}
          className={styles.CalendarIcon}
        />
        <span>
          <span>{T("Expiration Date:")}</span>
          <span style={{ fontWeight: "bold" }}>
            {" "}
            {getExpirationDateString(paymentMethod)}
          </span>
        </span>
      </span>
      <span>
        {isEqual(defaultPaymentMethodToken, paymentMethod.token) ? (
          <div style={{ color: appStyles.accentColor }}>
            <CheckmarkSmallIcon
              style={{ color: appStyles.accentColor, marginRight: "5px" }}
            />
            {isEqual(backPath, "/payment")
              ? T("Using this payment method")
              : T("Default payment method")}
          </div>
        ) : (
          <Button
            linkStyle
            appStyles={appStyles}
            style={{
              fontWeight: "bold",
              fontSize: "1rem",
              fontFamily: "unset",
              paddingLeft: 0,
            }}
            onClick={() => setDefaultPaymentMethod(paymentMethod.token)}
          >
            {isEqual(backPath, "/payment")
              ? T("Use this payment method")
              : T("Set as Default Payment Method")}
          </Button>
        )}
      </span>
    </Card.Content>
  </Card>
);

const getExpirationDateString = (paymentMethod) =>
  `${
    paymentMethod.expirationMonth < 10
      ? "0" + paymentMethod.expirationMonth
      : paymentMethod.expirationMonth
  }/${paymentMethod.expirationYear}`;

const humanReadablePaymentTypeString = (typeString) => {
  return typeString &&
    !includes(["spreedlytoast", "paymentsos", "spreedlypurchase"], typeString)
    ? startCase(typeString)
    : "Credit Card";
};
